import { IJointGroup, importantBits } from './joints'

export const poses: { [key: string]: { [idx: string]: number[][] } } = {
  left: {
    // TODO: re-enable was triggered by phony lol
    // Snapshot: [
    //   [
    //     0, 0.0597573616411407, 0.05597039264670981, 0.05659221161806142,
    //     0.021291086461522148,
    //   ],
    //   [
    //     0.0597573616411407, 0, 0.015559802625893696, 0.03424524997676795,
    //     0.07717465219722047,
    //   ],
    //   [
    //     0.05597039264670981, 0.015559802625893696, 0, 0.018734438937208393,
    //     0.0728145490102279,
    //   ],
    //   [
    //     0.05659221161806142, 0.03424524997676795, 0.018734438937208393, 0,
    //     0.07180424849921677,
    //   ],
    //   [
    //     0.021291086461522148, 0.07717465219722047, 0.0728145490102279,
    //     0.07180424849921677, 0,
    //   ],
    // ],
    Walk1: [
      [
        0, 0.09931101870208082, 0.06269222167842753, 0.04643074134550789,
        0.05090365124847403,
      ],
      [
        0.09931101870208082, 0, 0.07268531954699639, 0.12048283035341696,
        0.1332110641202932,
      ],
      [
        0.06269222167842753, 0.07268531954699639, 0, 0.10505664410267929,
        0.1112139522386378,
      ],
      [
        0.04643074134550789, 0.12048283035341696, 0.10505664410267929, 0,
        0.015214635827136157,
      ],
      [
        0.05090365124847403, 0.1332110641202932, 0.1112139522386378,
        0.015214635827136157, 0,
      ],
    ],
    Walk2: [
      [
        0, 0.04171218309075112, 0.07920973136599817, 0.05787655604752166,
        0.06450585664602361,
      ],
      [
        0.04171218309075112, 0, 0.0556840194739412, 0.09048375228223486,
        0.10127841657273244,
      ],
      [
        0.07920973136599817, 0.0556840194739412, 0, 0.11234655983701247,
        0.12594100344180162,
      ],
      [
        0.05787655604752166, 0.09048375228223486, 0.11234655983701247, 0,
        0.01710266751195812,
      ],
      [
        0.06450585664602361, 0.10127841657273244, 0.12594100344180162,
        0.01710266751195812, 0,
      ],
    ],
    Swipe: [
      [
        0, 0.03348944442278403, 0.035748379005681535, 0.03766253126328981,
        0.07367476457590896,
      ],
      [
        0.03348944442278403, 0, 0.016875792159032975, 0.028888324015462655,
        0.09961736566114908,
      ],
      [
        0.035748379005681535, 0.016875792159032975, 0, 0.012697977829023542,
        0.09437403172482056,
      ],
      [
        0.03766253126328981, 0.028888324015462655, 0.012697977829023542, 0,
        0.0894653529824639,
      ],
      [
        0.07367476457590896, 0.09961736566114908, 0.09437403172482056,
        0.0894653529824639, 0,
      ],
    ],
    SwipeOther: [
      [
        0, 0.08078659733628005, 0.09350004202211167, 0.1092196983287712,
        0.15736197965406235,
      ],
      [
        0.08078659733628005, 0, 0.014478818843341033, 0.03089512033221984,
        0.08989434303442337,
      ],
      [
        0.09350004202211167, 0.014478818843341033, 0, 0.01665959466433419,
        0.08407039835867264,
      ],
      [
        0.1092196983287712, 0.03089512033221984, 0.01665959466433419, 0,
        0.08118890250706919,
      ],
      [
        0.15736197965406235, 0.08989434303442337, 0.08407039835867264,
        0.08118890250706919, 0,
      ],
    ],
  },
  right: {
    SwipeOther: [
      [
        0, 0.07989537672305744, 0.0944663736409222, 0.10848955059295795,
        0.16266428417447223,
      ],
      [
        0.07989537672305744, 0, 0.016949359403942873, 0.030458478834395508,
        0.09859283344773746,
      ],
      [
        0.0944663736409222, 0.016949359403942873, 0, 0.014086799333727213,
        0.0938572840771014,
      ],
      [
        0.10848955059295795, 0.030458478834395508, 0.014086799333727213, 0,
        0.08876286795459294,
      ],
      [
        0.16266428417447223, 0.09859283344773746, 0.0938572840771014,
        0.08876286795459294, 0,
      ],
    ],
    Swipe: [
      [
        0, 0.04478615622736302, 0.05534348308437427, 0.06606722482815876,
        0.07590013131440945,
      ],
      [
        0.04478615622736302, 0, 0.019194501610114566, 0.03517178723604311,
        0.09859475981154411,
      ],
      [
        0.05534348308437427, 0.019194501610114566, 0, 0.01618770480789893,
        0.09512737773038535,
      ],
      [
        0.06606722482815876, 0.03517178723604311, 0.01618770480789893, 0,
        0.09515249473764524,
      ],
      [
        0.07590013131440945, 0.09859475981154411, 0.09512737773038535,
        0.09515249473764524, 0,
      ],
    ],
    // Snapshot: [
    //   [
    //     0, 0.05221198691161727, 0.04325815601736576, 0.03896946691801054,
    //     0.030524475034856113,
    //   ],
    //   [
    //     0.05221198691161727, 0, 0.015093493997519908, 0.030224405545116555,
    //     0.04243716092474642,
    //   ],
    //   [
    //     0.04325815601736576, 0.015093493997519908, 0, 0.015283831243613369,
    //     0.02844230686577347,
    //   ],
    //   [
    //     0.03896946691801054, 0.030224405545116555, 0.015283831243613369, 0,
    //     0.015423297041377513,
    //   ],
    //   [
    //     0.030524475034856113, 0.04243716092474642, 0.02844230686577347,
    //     0.015423297041377513, 0,
    //   ],
    // ],
    Walk1: [
      [
        0, 0.1079855212494816, 0.08207193276824938, 0.020798567760882188,
        0.03262642428795622,
      ],
      [
        0.1079855212494816, 0, 0.06550271568535172, 0.12008870697258106,
        0.1285862574302885,
      ],
      [
        0.08207193276824938, 0.06550271568535172, 0, 0.10087662547780488,
        0.11042267027232314,
      ],
      [
        0.020798567760882188, 0.12008870697258106, 0.10087662547780488, 0,
        0.013149101942110662,
      ],
      [
        0.03262642428795622, 0.1285862574302885, 0.11042267027232314,
        0.013149101942110662, 0,
      ],
    ],
    Walk2: [
      [
        0, 0.12017182696685748, 0.0329877597048749, 0.04412158688727415,
        0.055025740312271,
      ],
      [
        0.12017182696685748, 0, 0.10847151363909528, 0.1301119907579187,
        0.14122178513057074,
      ],
      [
        0.0329877597048749, 0.10847151363909528, 0, 0.027518716388127553,
        0.04124372590478418,
      ],
      [
        0.04412158688727415, 0.1301119907579187, 0.027518716388127553, 0,
        0.013770328951701612,
      ],
      [
        0.055025740312271, 0.14122178513057074, 0.04124372590478418,
        0.013770328951701612, 0,
      ],
    ],
  },
}

export function poseValue(hand: IJointGroup) {
  return importantBits.map((bit) =>
    importantBits.map((o_bit) =>
      hand.joints[bit].position.distanceTo(hand.joints[o_bit].position)
    )
  )
}
export function snapshotPose(hand: IJointGroup) {
  console.log(hand.handedness, JSON.stringify(poseValue(hand)))
}
